<template>
  <v-dialog v-model="showModal"  max-width="1000">
      <v-card class="show-billing-request-invoice">
        <v-card-title>
          <h3>Séléctionner les demandes de facturation</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-3">


          <v-row>
            <v-col >
              <v-data-table
                  class="br-table"
                  :headers="headers"
                  v-model="data"
                  :items="billingRequests"
                  :options.sync="options"
                  :loading="loading"
                  :server-items-length="total"
                  show-select
              >
                <template #item.createdAt="{item}">
                  {{ item.createdAt | formatDate($t('format_date')) }}
                </template>
                <template #item.startDate="{item}">
                  {{ formatUtcDate(item.startDate, $t('format_date')) }} - {{ formatUtcDate(item.endDate, $t('format_date')) }}
                </template>

                <template #item.netTotal="{item}">
                  {{ item.netTotal ? numberFormat(item.netTotal) + ' €' : '-' }}
                </template>

                <template #item.status="{item}">
                  {{ $t('billing-request.statues.' + item.status) }}
                </template>


                <template #item.options="{item}">
                  <v-btn icon small @click="openBillingRequest(item)">
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                </template>

              </v-data-table>
            </v-col>
          </v-row>





        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row dense>
            <v-col class="ma-0 text-right">
              <v-btn small @click="showModal = false">{{ $t('close') }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

    <billing-request-show-modal ref="billingRequestModal" />

  </v-dialog>
</template>

<script>
import BillingRequestRepository from "@repository/BillingRequestRepository";
import BillingRequestShowModal from "@modals/BillingRequestShowModal.vue";

export default {
  components: {BillingRequestShowModal},

  data() {
    return {
      data: this.value,
      showModal: false,
      billingRequests: [],
      selectedItems: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
      loading: false,
      total: 0,

    }
  },

  props: {
    value: Array,
    organizationId: String,
  },

  model: {
    prop: 'value',
    event: 'input'
  },

  mounted() {

  },

  watch: {
    showModal(val) {
      if(!val) {
        this.close()
      }
    },

    data() {
      this.$emit('input', this.data)
    },

    value(val) {
      this.data = val
    },

  },

  computed: {

    headers() {
      return [
        {text: this.$t('created-at'), value: 'createdAt'},
        {text: this.$t('billing-request.reference'), value: 'billingRequestReference'},
        {text: this.$t('period'), value: 'startDate'},
        {text: this.$t('name'), value: 'issuerName'},
        {text: this.$t('price-ht'), value: 'netTotal'},
        {text: this.$t('status'), value: 'status'},
        {value: 'options'},
      ]
    },


  },

  methods: {
    open() {
      this.showModal = true
      this.loadData()
    },

    close() {
      this.showModal = false
    },


    loadData() {

      this.loading = true
      let data = {
        organizationId: this.organizationId,
      }



      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      if (this.options.sortBy.length > 0) {
        data.sortBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc'
      }


      BillingRequestRepository.search(data).then(response => {
        this.total = response.total
        this.billingRequests = response.items
        this.loading = false
        this.showLoading(false)
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        this.loading = false
        this.showLoading(false)
      })
    },

    openBillingRequest(billingRequest) {
      this.$refs.billingRequestModal.open(billingRequest.id)
    },


  }
}
</script>



<style lang="scss">
.show-billing-request-invoice {
  .billing-requests {
    display: flex;
    align-content: center;
    gap: 5px;
  }
}
</style>