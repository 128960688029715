<template>
  <div class="pa-10 margin-manager">
    <title-page icon="ico-proforma-invoice">
      {{ $t('sale-margin.margin-manager') }}
    </title-page>

    <v-container>
      <v-row>
        <v-col>

          <v-row>
            <v-col class="text-right">
              <v-btn small @click="openCreateMarginRulesModal()">
                <v-icon small left>mdi-plus</v-icon>
                {{ $t('add') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-for="rule in marginRulesTreated" :key="rule.organization.id" class="mt-6 rule">
            <v-col>
              <headline>
                {{ rule.organization.name }}
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item dense link @click="openEditMarginRulesModal(rule)">
                      <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item dense link @click="deleteMarginRule(rule)">
                      <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </headline>
              <table class="margin-form-table ma-2">
                <thead>
                  <tr>
                    <th rowspan="2">{{ $t('sale-margin.scenario') }}</th>
                    <th rowspan="2">{{ $t('sale-margin.margin-type') }}</th>
                    <th colspan="2">{{ $t('sale-margin.operator-margin') }}</th>
                    <th colspan="2">{{ $t('sale-margin.roaming-fee') }}</th>
                    <th colspan="2">{{ $t('sale-margin.payment-processing-fee') }}</th>
                    <th colspan="2">{{ $t('sale-margin.invoice-payment-fee') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="scenario in rule.scenarios" :key="scenario.id">
                    <td class="scenario-name">
                      {{ $t(`sale-margin.scenarios.${scenario.id}`) }}
                    </td>
                    <td>
                      {{ $t('sale-margin.margin-types.' + scenario.marginType) }}
                    </td>



                    <td :class="{active: scenario.operatorFeeFixed}">
                      {{ canEditField(scenario.id,'operatorFeeFixed') ? scenario.operatorFeeFixed + ' €' : '-' }}
                    </td>
                    <td :class="{active: scenario.operatorFeePercent}">
                      {{ canEditField(scenario.id,'operatorFeePercent') ? scenario.operatorFeePercent + ' %' : '-' }}
                    </td>

                    <td :class="{active: scenario.roamingFeeFixed}">
                      {{ canEditField(scenario.id,'roamingFeeFixed') ? scenario.roamingFeeFixed + ' €' : '-' }}
                    </td>
                    <td :class="{active: scenario.roamingFeePercent}">
                      {{ canEditField(scenario.id,'roamingFeePercent') ? scenario.roamingFeePercent + ' %' : '-'  }}
                    </td>

                    <td :class="{active: scenario.paymentProcessingFeeFixed}">
                      {{ canEditField(scenario.id,'paymentProcessingFeeFixed') ? scenario.paymentProcessingFeeFixed + ' €' : '-' }}
                    </td>

                    <td :class="{active: scenario.paymentProcessingFeePercent}">
                      {{ canEditField(scenario.id,'paymentProcessingFeePercent') ? scenario.paymentProcessingFeePercent + ' %' : '-'  }}
                    </td>

                    <td :class="{active: scenario.invoicePaymentFeeFixed}">
                      {{ canEditField(scenario.id,'invoicePaymentFeeFixed') ? scenario.invoicePaymentFeeFixed + ' €' : '-' }}
                    </td>
                    <td :class="{active: scenario.invoicePaymentFeePercent}">
                      {{ canEditField(scenario.id,'invoicePaymentFeePercent') ? scenario.invoicePaymentFeePercent + ' %' : '-'  }}
                    </td>

                  </tr>
                </tbody>
              </table>

            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <margin-form ref="marginRulesModal" />

  </div>
</template>

<script>
import MarginForm from "@pages/MarginManager/modal/MarginForm.vue";
import MarginRuleRepository from "@repository/MarginRuleRepository";

export default {
  components: {
    MarginForm,
  },

  data() {
    return {
      scenarios: [
        "usr-zms-to-cp-zms",
        "usr-gireve-to-cp-zms",
        "usr-zms-to-cp-gireve",
        "usr-qrcode-to-cp-zms"
      ],
      marginRules: [],
    }
  },


  mounted() {
    this.loadData()
  },

  computed: {
    headers() {
      return [
        {text: this.$t('sale-margin.scenario'), value: 'scenario'},
        {text: this.$t('sale-margin.roaming-fee'), value: 'scenario'},
        {text: this.$t('sale-margin.operator-fee'), value: 'scenario'},
        {text: this.$t('sale-margin.cb-fee'), value: 'scenario'},
        {text: this.$t('sale-margin.invoice-payment-fee'), value: 'scenario'},
      ]
    },

    marginRulesTreated() {
      return this.marginRules.map(rule => {
        return {
          id: rule.id,
          organization: rule.organization,
          scenarios: this.scenarios.map(s => {
            let scenario = rule.scenarios.find(sc => sc.id === s) ?? null
            if(!scenario) {
              scenario = {
                id: s,
                marginType: 'commission',

                roamingFeeFixed: 0,
                roamingFeePercent: 0,

                operatorFeeFixed: 0,
                operatorFeePercent: 0,

                paymentProcessingFeeFixed: 0,
                paymentProcessingFeePercent: 0,

                invoicePaymentFeeFixed: 0,
                invoicePaymentFeePercent: 0,
              }
            }

            return scenario
          })
        }
      })
    }
  },

  methods: {

    canEditField(scenarioId, field) {

      if(field === 'marginType') {
          return scenarioId !== 'usr-zms-to-cp-gireve'
      }

      if(scenarioId === 'usr-zms-to-cp-zms') {
        return field !== 'roamingFeeFixed' && field !== 'roamingFeePercent'
      }


      if(scenarioId === 'usr-gireve-to-cp-zms') {
        return field === 'operatorFeeFixed' || field === 'operatorFeePercent'
      }

      if(scenarioId === 'usr-zms-to-cp-gireve') {
        return true
      }

      if(scenarioId === 'usr-qrcode-to-cp-zms') {
        return field !== 'roamingFeeFixed' && field !== 'roamingFeePercent' && field !== 'invoicePaymentFeeFixed' && field !== 'invoicePaymentFeePercent'
      }

      return false
    },

    loadData() {
      MarginRuleRepository.search({}).then(data => {
        this.total = data.total
        this.marginRules = data.items
      }).catch(err => {
        this.notifyError(err)
      })
    },

    openCreateMarginRulesModal() {
      const rule = {
        creation: true,
        organization: {
          id: null,
        },
        scenarios: this.scenarios.map(s => {
          return {
            id: s,
            marginType: s !== 'usr-zms-to-cp-gireve' ? 'commission' : 'markup',

            roamingFeeFixed: 0,
            roamingFeePercent: 0,

            operatorFeeFixed: 0,
            operatorFeePercent: 0,

            paymentProcessingFeeFixed: 0,
            paymentProcessingFeePercent: 0,

            invoicePaymentFeeFixed: 0,
            invoicePaymentFeePercent: 0,
          }
        })
      }
      this.$refs.marginRulesModal.open(rule)
    },

    openEditMarginRulesModal(rule) {
      this.$refs.marginRulesModal.open(rule)
    },

    deleteMarginRule(rule) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          MarginRuleRepository.delete(rule.id).then(() => {
            this.loadData()
            this.notifySuccess(this.$t('delete-success'))
          }).catch(err => {
            this.notifyError(err)
          })
        }
      })
    },
  }
}
</script>

<style lang="scss">


  .margin-manager {
    .margin-form-table {
      width: 100% !important;
      border-collapse: collapse;
      tr {
        height: 50px;
        th {
          border-right: 1px solid #ccc;
          font-family: dosis, sans-serif;
          font-size: 18px;
          font-weight: 600;
          color: #5F6569;
          &:last-child {
            border-right: 0;
          }
        }
        td {
          text-align: center;
          vertical-align: middle;
          padding: 3px 10px;
          font-weight: 500;
          &.active {
            font-weight: bold;
            color: var(--v-primary-base);
          }
        }
      }
      .scenario-name {
        width: 200px;
        color:  var(--v-primary-base);
        font-weight: bold;
      }
    }
    .headline-title {
      .v-btn {
        position: absolute;
        right: 0;
      }
    }

  }

</style>