<template>
  <div class="pa-10 proforma-invoice">
    <title-page icon="ico-proforma-invoice">
      {{ $tc('proforma-invoice.proforma-invoice', 2) }}
    </title-page>



    <v-tabs class="elevation-3">
      <v-tab :to="{params: {recipientType: 'organization'}}">
        {{ $t('organization.organizations')}}
      </v-tab>
      <v-tab :to="{params: {recipientType: 'customer'}}">
        {{ $tc('customer.customer', 2)}}
      </v-tab>
      <v-tab :to="{params: {recipientType: 'member'}}">
        {{ $tc('member.member', 2)}}
      </v-tab>
      <v-tab :to="{params: {recipientType: 'agreement'}}">
        {{ $tc('roaming', 2)}}
      </v-tab>
    </v-tabs>


    <v-row>
      <v-divider></v-divider>
      <v-col cols="3">
        {{ selectedProformaInvoiceToSearch }}
        <v-autocomplete
            :placeholder="$t('proforma-invoice.search-by-number')"
            v-model="selectedProformaInvoiceToSearch"
            :search-input.sync="searchByProformaInvoiceNumberQuery"
            return-object
            no-filter
            clearable
            :items="searchProformaInvoiceByNumberResult"
        >
          <template #selection="{ item }">
            N° {{ item.proformaInvoiceReference }}
          </template>
          <template #item="{ item }">
            <v-row>
              <v-col>
                N° {{ item.proformaInvoiceReference }}
              </v-col>
            </v-row>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>






    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <date-picker
                label="Date de création"
                v-model="rangeDate"
                hide-details
                clearable
                range
            />
          </v-col>
          <v-col>
            <date-picker
                label="Date de la période"
                v-model="rangePeriodDate"
                hide-details
                clearable
                range
            />
          </v-col>
          <v-col>
            <v-select
                label="Statut"
                v-model="filters.status"
                :items="statues"
                hide-details
                clearable
                range
            />
          </v-col>
        </v-row>
        <template v-if="recipientType === 'organization'">
          <v-row>
            <v-col>
              <organization-select
                  :label="$t('organization.organization')"
                  v-model="selectedOrganizationId"
                  hide-details
                  clearable
              />
            </v-col>
            <v-col>
              <v-select
                  :label="$t('proforma-invoice.content-type')"
                  clearable
                  hide-details
                  v-model="filters.contentType"
                  :items="[
                    {text: $tc('enums.invoice-content-type.subscription', 2), value: 'subscription'},
                    {text: $t('enums.invoice-content-type.usage'), value: 'usage'},
                  ]"
              />
            </v-col>
            <v-col v-if="filters.contentType === 'subscription'">
              <v-select
                  :label="$tc('subscription.subscription-plan')"
                  clearable
                  v-model="filters.subscriptionPlanId"
                  hide-details
                  :items="subscriptionPlansTreated"
                  item-text="name"
                  item-value="id"
              />
            </v-col>

          </v-row>
        </template>

        <template v-if="recipientType === 'customer'">
          <v-row>
            <v-col>
              <customer-select
                  :label="$tc('customer.customer')"
                  clearable
                  v-model="selectedCustomerId"
              />
            </v-col>

          </v-row>
        </template>

        <template v-if="recipientType === 'member'">
          <v-row>
            <v-col>
              <member-select
                  :label="$tc('member.member')"
                  clearable
                  v-model="selectedMemberId"
              />
            </v-col>

          </v-row>
        </template>

        <template v-if="recipientType === 'agreement'">
          <v-row>
            <v-col>
              <v-autocomplete
                  :label="$tc('operator.operator')"
                  v-model="filters.operatorId"
                  :items="operators"
                  item-text="name"
                  item-value="id"
                  clearable
                  hide-details
              >
                <template #item="{item}">
                  {{ item.name }}<template v-if="item.id !== item.name "> ({{ item.id }})</template>
                </template>

                <template #selection="{item}">
                  {{ item.name }}<template v-if="item.id !== item.name "> ({{ item.id }})</template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col>
              <v-autocomplete
                  :label="$tc('agreement.partner')"
                  v-model="filters.partnerOperators"
                  :items="partners"
                  item-text="name"
                  item-value="id"
                  hide-details
                  clearable
              >
                <template #item="{item}">
                  {{ item.name }}<template v-if="item.id !== item.name "> ({{ item.id }})</template>
                </template>

                <template #selection="{item}">
                  {{ item.name }}<template v-if="item.id !== item.name "> ({{ item.id }})</template>
                </template>
              </v-autocomplete>
            </v-col>

          </v-row>
        </template>
      </v-card-text>
    </v-card>

    <v-divider class="my-6"/>



    <v-card v-if="stats.statues?.length > 1">
      <v-card-text>
        <v-simple-table class="stats-table">
          <template #default>
            <thead>
              <tr>
                <th class="text-center">Statut</th>
                <th>Nombre</th>
                <th>Montant HT</th>
                <th>Montant TTC</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(stat, i) in stats.statues" :key="i">
                  <td class="stats-status text-center">
                    <a @click.prevent="changeStatus(stat.status)">
                      {{ $t('proforma-invoice.statues.' + stat.status) }}
                    </a>
                  </td>
                  <td>{{ stat.count }}</td>
                  <td>{{ numberFormat(stat.net) }} €</td>
                  <td>{{ numberFormat(stat.final) }} €</td>
              </tr>
              <tr class="stats-total">
                <td>Total</td>
                <td>{{ stats.count }}</td>
                <td>{{ numberFormat(stats.net) }} €</td>
                <td>{{ numberFormat(stats.final) }} €</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card class="my-3" color="primary" v-else>
      <v-card-text class=" white--text" style="font-size: 20px;">
        <v-row>
          <v-col>
            <v-row>
              <v-col>Nb de factures</v-col>
              <v-col class="text-right"><b>{{ stats.count }}</b></v-col>
            </v-row>
          </v-col>
          <v-divider inset vertical />
          <v-col>
            <v-row>
              <v-col>Total HT</v-col>
              <v-col class="text-right"><b>{{ numberFormat(stats.net) }} €</b></v-col>
            </v-row>
          </v-col>
          <v-divider inset vertical />
          <v-col>
            <v-row>
              <v-col>Total TTC</v-col>
              <v-col class="text-right"><b>{{ numberFormat(stats.final) }} €</b></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>



    <template v-if="activeFilterName">
      <v-divider class="my-6"/>
      <v-row>
        <v-col class="text-right">
          {{ $t('proforma-invoice.active-filter')}} :
          <v-chip small close @click:close="removeActiveFilter">{{ activeFilterName }}</v-chip>
        </v-col>
      </v-row>
    </template>


    <v-divider class="my-6"/>

    <v-row>
      <v-col >
        <v-data-table
          :headers="headers"
          :items="proformaInvoices"
          :options.sync="options"
          :loading="loading"
          :server-items-length="total"
          v-model="selectedProformaInvoices"
          :show-select="!!activeFilter"
          :expanded.sync="expanded"
          single-expand
          show-expand
        >


          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-3">
              <v-data-table
                :items="item.children"
                :headers="expandHeaders"
                :hide-default-footer="item.children?.length <= 10"
              >
                <template #item.createdAt="{item}">
                  {{ item.createdAt | formatDate($t('format_date')) }}
                </template>

                <template #item.startDate="{item}">
                  {{ formatUtcDate(item.startDate, $t('format_date')) }} - {{ formatUtcDate(item.endDate, $t('format_date')) }}
                </template>

                <template #item.netTotal="{item}">
                  {{ item.netTotal ? numberFormat(item.netTotal) + ' €' : '-' }}
                </template>

                <template #item.proformaInvoiceContentType="{item}">
                  {{ $t('enums.invoice-content-type.' + item.proformaInvoiceContentType) }}
                </template>

                <template #item.options="{item}">
                  <v-btn :disabled="!item.isSelectable" icon small :to="{name: 'proforma-invoices.edit', params: {id: item._id}}" target="_blank" exact>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>

              </v-data-table>
            </td>
          </template>

          <template #item.data-table-expand="{expand, isExpanded, item }">
            <template v-if="item.children?.length > 0">
              <v-btn icon @click="expand(!isExpanded)">
                <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
          </template>

          <template #item.createdAt="{item}">
            {{ item.createdAt | formatDate($t('format_date')) }}
          </template>

          <template #item.startDate="{item}">
            {{ formatUtcDate(item.startDate, $t('format_date')) }} - {{ formatUtcDate(item.endDate, $t('format_date')) }}
          </template>

          <template #item.netTotal="{item}">
            {{ item.netTotal ? numberFormat(item.netTotal) + ' €' : '-' }}
          </template>

          <template #item.status="{item}">
            {{ $t('proforma-invoice.statues.' + item.status) }}
          </template>

          <template #item.operators="{item}">
            <v-chip color="primary" x-small v-for="operator in item.operators" :key="operator">{{ operator }}</v-chip>
          </template>

          <template #item.partnerOperators="{item}">
            <v-chip color="blue" dark x-small v-for="operator in item.partnerOperators" :key="operator">{{ operator }}</v-chip>
          </template>

          <template #item.recipientName="{item}">
            <router-link  :to="generateTo(item)">
              {{ item.recipientName }}
            </router-link>
          </template>

          <template #item.proformaInvoiceContentType="{item}">
            {{ $t('enums.invoice-content-type.' + item.proformaInvoiceContentType) }}
          </template>

          <template #item.options="{item}">
            <div class="d-flex gap-2">
              <v-btn icon small @click="openInvoice(item)">
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
              <v-btn :disabled="!item.isEditable" icon small :to="{name: 'proforma-invoices.edit', params: {id: item.id}}" exact>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:footer.prepend>
            <v-btn small @click="downloadCsv" text>
              <v-icon left>mdi-download</v-icon>
              {{ $t('export') }}
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>


    <template v-if="selectedProformaInvoices.length > 1">
      <v-row>
        <v-col>
          {{ $tc('proforma-invoice.selected-proforma-invoice', selectedProformaInvoices.length) }} : {{ selectedProformaInvoices.length }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex gap-3">
          <v-chip small :key="selectProformaInvoice.id" v-for="selectProformaInvoice in selectedProformaInvoices" close @click:close="removeSelectedProformaInvoice(selectProformaInvoice)">{{ selectProformaInvoice.proformaInvoiceReference}}</v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn small @click="createProformaInvoiceGroup">
            <v-icon left>mdi-group</v-icon>
            {{ $t('proforma-invoice.group-proforma-invoices') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>


    <proforma-invoice-show-modal ref="proformaInvoiceModal" />

  </div>
</template>

<script>
import OrganizationSelect from "@blocks/Select/OrganizationSelect.vue";
import ProformaInvoiceRepository from "@repository/ProformaInvoiceRepository";
import ProformaInvoiceShowModal from "./modals/ProformaInvoiceShowModal.vue";
import CustomerSelect from "@blocks/Select/CustomerSelect.vue";
import MemberSelect from "@blocks/Select/MemberSelect.vue";
import OperatorRepository from "@repository/OperatorRepository";
import SubscriptionRepository from "@repository/SubscriptionRepository";

export default {
  components: {
    OrganizationSelect,
    CustomerSelect,
    MemberSelect,
    ProformaInvoiceShowModal,
  },

  data() {
    return {
      loading: false,
      searchByProformaInvoiceNumberQuery: null,
      selectedProformaInvoiceToSearch: null,
      searchProformaInvoiceByNumberResult: null,
      expanded: [],
      allOperators: [],
      rangeDate: [],
      rangePeriodDate: [],
      selectedProformaInvoices: [],
      activeFilter: null,
      stats: {
        count: 0,
        net: 0,
        final: 0,
        statues: [],
      },
      filters: {
        status: null,
        organizationId: null,
        contentType: null,
        customerId: null,
        operatorId: null,
        partnerOperators: null,
      },
      total: 0,
      proformaInvoices: [],
      subscriptionPlans: [],
      options: null,
    }
  },

  mounted() {
    this.loadOperators()
    this.loadSubscriptionPlans()
  },

  watch: {
    filters: {
      handler() {
        if(this.options.page > 1) {
          this.options.page = 1
        } else {
          this.loadData()
        }
      },
      deep: true
    },

    selectedProformaInvoiceToSearch(val) {
      if(val) {
        let id = val.id
        if(val.parentId) {
          id = val.parentId
        }
        this.$router.push({name: 'proforma-invoices.edit', params: {id}})
        this.selectedProformaInvoiceToSearch = null
      }
    },

    rangePeriodDate() {
      this.loadData()
    },

    rangeDate() {
      this.loadData()
    },

    options() {
      this.loadData()
    },

    searchByProformaInvoiceNumberQuery(query) {
      this.loadSearchByProformaInvoiceNumber(query)
    },

    '$route.query'() {
      this.selectedProformaInvoices = []
      if(this.options.page > 1) {
        this.options.page = 1
      } else {
        this.loadData()
      }
    },

    recipientType() {
      this.showLoading(true)
      this.filters = {
        status: null,
        organizationId: null,
        contentType: null,
        customerId: null,
        operatorId: null,
        partnerOperators: null,
      }
    },

  },

  computed: {

    activeFilterName() {
      if(this.activeFilter) {
        if(this.activeFilter.name) {
          return this.activeFilter.name
        } else if(this.activeFilter.firstname) {
          return this.activeFilter.firstname + ' ' + this.activeFilter.lastname
        } else if(this.activeFilter.email) {
          return this.activeFilter.email
        }
      }
      return null
    },

    statues() {
      const statues = []
      for(let a in this.$t('proforma-invoice.statues')) {
        statues.push({
          value: a,
          text: this.$t('proforma-invoice.statues.' + a)
        })
      }
      return statues
    },


    selectedOrganizationId: {
      get() {
        return this.$route.query.organizationId
      },
      set(organizationId) {
        let query = {...this.$route.query}
        if(organizationId) {
          query.organizationId = organizationId
        } else {
          delete query.organizationId
        }
        this.$router.push({query})
      }
    },

    selectedMemberId: {
      get() {
        return this.$route.query.memberId
      },
      set(memberId) {
        let query = {...this.$route.query}
        if(memberId) {
          query.memberId = memberId
        } else {
          delete query.memberId
        }
        this.$router.push({query})
      }
    },

    selectedCustomerId: {
      get() {
        return this.$route.query.customerId
      },
      set(customerId) {
        let query = {...this.$route.query}
        if(customerId) {
          query.customerId = customerId
        } else {
          delete query.customerId
        }
        this.$router.push({query})
      }
    },

    selectedAgreementId: {
      get() {
        return this.$route.query.agreementId
      },
      set(agreementId) {
        let query = {...this.$route.query}
        if(agreementId) {
          query.agreementId = agreementId
        } else {
          delete query.agreementId
        }
        this.$router.push({query})
      }
    },

    recipientType() {
      return this.$route.params.recipientType ?? 'organization'
    },

    headers() {
      const headers = [
        { text: '', value: 'data-table-expand' },
        {text: this.$t('created-at'), value: 'createdAt'},
        {text: this.$t('proforma-invoice.reference'), value: 'proformaInvoiceReference'},
        {text: this.$t('period'), value: 'startDate'},
        // {text: this.$t('proforma-invoice.recipient-type'), value: 'recipientType'},
        {text: this.$t('proforma-invoice.content-type'), value: 'proformaInvoiceContentType'},
        {text: this.$t('proforma-invoice.issuer'), value: 'issuerName'},
        {text: this.$t('proforma-invoice.recipient'), value: 'recipientName'},
        {text: this.$t('price-ht'), value: 'netTotal'},
        {text: this.$t('status'), value: 'status'},
        {value: 'options', width: 100},
      ];

      if(this.$route?.params?.recipientType === 'agreement') {
        headers.splice(4, 1)
        headers.splice(5, 0, {text: this.$tc('operator.operator', 2), value: 'operators'})
        // headers.splice(7, 0, {text: this.$tc('operator.operator', 2), value: 'partnerOperators'})
      }

      return headers
    },

    expandHeaders() {
      const headers = [
        {text: this.$t('created-at'), value: 'createdAt'},
        {text: this.$t('proforma-invoice.reference'), value: 'proformaInvoiceReference'},
        {text: this.$t('period'), value: 'startDate'},
        {text: this.$t('proforma-invoice.content-type'), value: 'proformaInvoiceContentType'},
        {text: this.$t('price-ht'), value: 'netTotal'},
      ]

      if(this.recipientType === 'agreement') {
        headers.splice(3, 0, {text: this.$tc('operator.operator', 2), value: 'operators'})
      }

      return headers

    },

    subscriptionPlansTreated() {
      return this.subscriptionPlans.map(plan => {
        return {
          id: plan.id,
          // name: this.localizedString(plan.label)
          name: plan.name
        }
      })
    },


    operators() {
      return this.allOperators.filter(partner => partner.ocpi)
    },

    partners() {
      return this.allOperators.filter(partner => !partner.ocpi)
    },

  },

  methods: {

    changeStatus(status) {
      this.filters.status = status
    },

    loadData() {

      this.loading = true
      let data = {
        recipientType: this.recipientType,
        ...this.filters,
      }


      if(this.rangeDate?.length > 0) {
        data.startDate = this.rangeDate[0]
        data.endDate = this.rangeDate[1]
      }

      if(this.rangePeriodDate?.length > 0) {
        data.startPeriodDate = this.rangePeriodDate[0]
        data.endPeriodDate = this.rangePeriodDate[1]
      }

      if(this.$route.query.organizationId) {
        data.organizationId = this.$route.query.organizationId
      }

      if(this.$route.query.memberId) {
        data.memberId = this.$route.query.memberId
      }

      if(this.$route.query.customerId) {
        data.customerId = this.$route.query.customerId
      }

      if(this.$route.query.agreementId) {
        data.agreementId = this.$route.query.agreementId
      }

      if(this.$route.query.operatorGroupId) {
        data.operatorGroupId = this.$route.query.operatorGroupId
      }

      if(this.$route.query.operatorId) {
        data.operatorId = this.$route.query.operatorId
      }

      if(this.$route.query.partnerOperatorGroupId) {
        data.partnerOperatorGroupId = this.$route.query.partnerOperatorGroupId
      }
      if(this.$route.query.partnerOperatorId) {
        data.partnerOperatorId = this.$route.query.partnerOperatorId
      }


      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      if (this.options.sortBy.length > 0) {
        data.sortBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc'
      }


      ProformaInvoiceRepository.search(data).then(response => {
        this.stats = response.stats
        this.activeFilter = response.activeFilter
        this.total = response.total
        this.proformaInvoices = response.items.map(pi => {
          return {
            ...pi,
            children: pi.children.map(child => {
              return {
                ...child,
                isSelectable: ['under-review', 'pending'].includes(child.status),
              }
            }),
            isSelectable: ['under-review', 'pending'].includes(pi.status) && !pi.proformaInvoiceGroup,
            isEditable: ['under-review', 'rejected', 'pending', 'validated', 'invoiced'].includes(pi.status),
            expandable: false,
          }
        })
        this.loading = false
        this.showLoading(false)
      }).catch(err => {
        this.notifyError(err)
        this.loading = false
        this.showLoading(false)
      })
    },

    loadSearchByProformaInvoiceNumber(query) {
      ProformaInvoiceRepository.searchByNumber(query).then(result => {
        this.searchProformaInvoiceByNumberResult = result
      }).catch(err => {
        this.notifyError(err)
      })
    },

    loadSubscriptionPlans() {
      SubscriptionRepository.searchPlans({limit: 999}).then(result => {
        this.subscriptionPlans = result.items
      }).catch(err => {
        this.notifyError(err)
      })
    },

    openInvoice(proformaInvoice) {
      this.$refs.proformaInvoiceModal.open(proformaInvoice.id)
    },

    loadOperators() {
      OperatorRepository.search().then(operators => {
        this.allOperators = operators
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
      })
    },

    downloadCsv() {
      this.showLoading(true)

      let data = {
        recipientType: this.recipientType,
        ...this.filters,
      }


      if(this.rangeDate?.length > 0) {
        data.startDate = this.rangeDate[0]
        data.endDate = this.rangeDate[1]
      }

      if(this.rangePeriodDate?.length > 0) {
        data.startPeriodDate = this.rangePeriodDate[0]
        data.endPeriodDate = this.rangePeriodDate[1]
      }

      ProformaInvoiceRepository.exportCsv(data).then(() => {
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    downloadPdf(proformaInvoice) {
      this.showLoading(true)
      ProformaInvoiceRepository.pdf(proformaInvoice.id).then(() => {
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    downloadAttachment(proformaInvoice) {
      this.showLoading(true)
      ProformaInvoiceRepository.downloadAttachment(proformaInvoice.id).then(() => {
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    generateTo(item) {
      if(item.organizationId) {
        return {query: {organizationId: item.organizationId}}
      } else if(item.customerId) {
        return {query: {customerId: item.customerId}}
      } else if(item.memberId) {
        return {query: {memberId: item.memberId}}
      } else if(item.partnerOperatorGroupId) {
        return {query: {partnerOperatorGroupId: item.partnerOperatorGroupId}}
      } else if(item.agreementId) {
        return {query: {agreementId: item.agreementId}}
      } else {
        return {}
      }
    },

    removeActiveFilter() {
      this.$router.push({query: {}})
    },

    removeSelectedProformaInvoice(item) {
      this.selectedProformaInvoices.splice(this.selectedProformaInvoices.indexOf(item), 1)
    },

    createProformaInvoiceGroup() {
      this.showLoading(true)
      ProformaInvoiceRepository.createProformaInvoicesGroup(this.selectedProformaInvoices.map(pi => pi.id)).then((data) => {
        this.showLoading(false)
        this.$router.push({name: 'proforma-invoices.edit', params: {id: data.id}})
      }).catch(err => {
        this.showLoading(false)
        this.notifyError(err)
      })
    },

  },

}
</script>

<style lang="scss">
.proforma-invoice {
  table {
    tr {
      &:first-child {
        th {
          &:first-child {
            i {
              padding-left: 10px;
            }
            .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
              background-color: transparent !important;
            }
          }
        }
      }
      td {
        a {
          font-size: 14px;
        }
      }
    }
  }
}

.stats-table {
  tr {
    &.stats-total {
      td {
        background: red;
        color: white;
        font-weight: bold;
      }
    }
    td, th {
      height: 30px !important;
      padding: 10px 3px;
      text-align: right !important;
      &.stats-status {
        font-weight: bold;
      }
    }
  }
}

</style>
