<template>
  <div class="pa-10 edit-billing-request-invoice">
    <title-page icon="ico-billingRequest">
      {{ $t('billing-request-invoice.invoice-transmission') }}
    </title-page>

    <v-breadcrumbs
        :items="[
            {text: $tc('billing-request-invoice.billing-request-invoice', 2), to: {name: 'billing-requests.invoices'}, exact: true}
        ]"
        divider=">"
    ></v-breadcrumbs>

    <v-row v-if="form.status !== 'draft'">
      <v-col>
        <v-alert type="info">
          {{ $t('billing-request-invoice.invoice-already-submitted') }}
        </v-alert>
      </v-col>
    </v-row>
    <v-form ref="form" @submit.prevent="save" v-else>

      <div class="grid pt-0">

        <headline>
          {{ $tc('invoice.invoice')}}
        </headline>


        <v-row dense>
          <v-col class="label">
            {{ $t('date')}}
          </v-col>
          <v-col>
            {{ formatDate(form.createdAt, $t('format_date')) }}
          </v-col>
        </v-row>

        <v-row dense>
          <v-col class="label">
            {{ $t('billing-request-invoice.invoice-reference')}}
          </v-col>
          <v-col>
            <v-text-field v-model="form.reference"></v-text-field>
          </v-col>
        </v-row>

        <v-row dense class="justify-center">
          <v-col cols="6">
            <v-alert dense type="info">
              {{ $t('billing-request-invoice.invoice-reference-required')}}
            </v-alert>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col class="label">
            {{ $t('status')}}
          </v-col>
          <v-col>
            {{ $t('billing-request-invoice.statues.' + form.status) }}
          </v-col>
        </v-row>



        <v-row dense>
          <v-col class="label">
            {{ $t('created-by')}}
          </v-col>
          <v-col>
            {{ form.createdBy?.firstname }} {{ form.createdBy?.lastname }}
          </v-col>
        </v-row>



        <headline class="mt-10">
          {{ $t('organization.organization')}}
        </headline>
        <v-row dense>
          <v-col class="label">
            {{ $t('name')}}
          </v-col>
          <v-col>
            <template>
              {{ form.organization.name ?? '-' }}
            </template>
          </v-col>
        </v-row>




        <headline class="mt-10">
          {{ $tc('billing-request-invoice.linked-billing-request', 2)}}
        </headline>
        <v-row dense>
          <v-col class="label">
            {{ $tc('billing-request-invoice.linked-billing-request', 2)}}
          </v-col>
          <v-col>
            <div class="billing-requests">
              <v-chip @click="openBillingRequest(billingRequest.id)" v-for="billingRequest in form.billingRequests" :key="billingRequest.id">
                {{ billingRequest.billingRequestReference }}
              </v-chip>
              <div v-if="!form.billingRequests?.length">
                <v-chip>
                  {{ $t('billing-request-invoice.no-billing-request') }}
                </v-chip>
              </div>

            </div>



          </v-col>
        </v-row>
        <v-alert dense outlined type="warning" v-if="!form.billingRequests?.length">{{ $t('billing-request.select-at-least-one-billing-request')}}</v-alert>



        <v-row dense>
          <v-col class="align-center">
            <v-btn small @click="openSelectBillingRequestModal">
              {{ $t('billing-request-invoice.manage-billing-requests') }}
            </v-btn>
          </v-col>
        </v-row>

        <headline class="mt-10">
          {{ $t('amount') }}
        </headline>
        <v-row dense>
          <v-col class="label">
            {{ $t('billing-request-invoice.net-expected-amount')}}
          </v-col>
          <v-col>
            {{ netExceptedAmount ? numberFormat(netExceptedAmount) + ' €' : '-' }}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="label">
            {{ $t('billing-request-invoice.total-expected-amount')}}
          </v-col>
          <v-col>
            {{ totalExceptedAmount ? numberFormat(totalExceptedAmount) + ' €' : '-' }}
          </v-col>
        </v-row>

        <v-row dense>
          <v-col class="label">
            {{ $tc('attachment')}}
          </v-col>
          <v-col>
            <v-row align-content="center" v-if="form.file">
              <v-col>
                <a href="#" @click.prevent="downloadFile()" >
                  {{ form.file.filename }}
                </a>
                <v-btn icon x-small @click="deleteAttachment">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <template v-else>
              {{ $t('billing-request-invoice.no-attachment') }}
            </template>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col class="label">
            {{ $t('billing-request-invoice.upload-invoice')}}
          </v-col>
          <v-col>
            <v-file-input :placeholder="$t('billing-request-invoice.upload-invoice-placeholder')" v-model="invoiceFile"></v-file-input>
          </v-col>
        </v-row>

      </div>




      <headline class="mt-10">
        Actions
      </headline>
      <v-row justify="center" class="gap-4">
        <v-btn small @click="cancel">{{ $t('change-cancel') }}</v-btn>
        <v-btn class="ml-3 primary" :disabled="!canSave" small type="submit">
          {{ $t('change-save') }}
        </v-btn>
      </v-row>


      <v-row>
        <v-col class="text-center font-weight-light">
          {{ $t('billing-request-invoice.update-invoice-notes') }}
        </v-col>
      </v-row>

    </v-form>
    <billing-request-show-modal ref="billingRequestModal" />
    <select-billing-requests-modal ref="selectBillingRequestModal" :organization-id="form.organization?.id" v-model="form.billingRequests" />
  </div>
</template>

<script>
import BillingRequestRepository from "@repository/BillingRequestRepository";
import BillingRequestShowModal from "@modals/BillingRequestShowModal.vue";
import SelectBillingRequestsModal from "@pages/BillingRequest/modal/SelectBillingRequestsModal.vue";
import RulesMixin from "@mixins/RulesMixin";

export default {
  components: {
    BillingRequestShowModal,
    SelectBillingRequestsModal,
  },

  data() {
    return {
      form: {
        billingRequests: [],
      },
      dataChanged: false,
      invoiceFile: null,
      fileDeleted: false,
    }
  },

  mixins: [
    RulesMixin,
  ],


  mounted() {
    this.loadData()
  },

  watch: {

  },

  computed: {

    id() {
      return this.$route.params.id
    },


    netExceptedAmount() {
      return this.form.billingRequests.reduce((acc, item) => acc + item.netTotal, 0)
    },

    totalExceptedAmount() {
      return this.form.billingRequests.reduce((acc, item) => acc + item.finalTotal, 0)
    },

    canSave() {
      return this.form.billingRequests.length > 0
    }

  },

  methods: {

    openBillingRequest(billingRequestId) {
      this.$refs.billingRequestModal.open(billingRequestId)
    },

    loadData() {
      return new Promise((resolve, reject) => {
        this.showLoading(true)
        BillingRequestRepository.getInvoice(this.id).then(data => {
          this.form = data
          this.fileDeleted = false
          this.showLoading(false)
          resolve(data)
        }).catch(error => {
          this.notifyError(error)
          this.showLoading(false)
          reject(error)
        })
      })
    },

    save() {
      if(this.$refs.form.validate()) {
        if(this.form.billingRequests.length === 0) {
          this.notifyError('Veuillez sélectionner au moins une demande de facturation')
          return
        }

        this.showLoading(true)

        BillingRequestRepository.updateBillingDetailInvoice(this.id, this.form.reference, this.form.billingRequests.map(item => item.id), this.invoiceFile, this.fileDeleted).then(data => {
          this.dataChanged = true
          this.$router.replace({name: 'billing-requests.invoices.show'})
        }).catch(error => {
          this.notifyError(error)
          this.showLoading(false)
        })
      }

    },

    downloadFile() {
      this.showLoading(true)
      BillingRequestRepository.downloadInvoiceFile(this.id).then(data => {
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },


    deleteAttachment() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.form.file = null
          this.fileDeleted = true
        }
      })
    },


    deleteBillingRequest(id) {
      this.form.billingRequests = this.form.billingRequests.filter(item => item.id !== id)
    },

    openSelectBillingRequestModal() {
      this.$refs.selectBillingRequestModal.open()
    },

    cancel() {
      this.$router.replace({name: 'billing-requests.invoices.show'})
    },

  }
}
</script>



<style lang="scss">
.edit-billing-request-invoice {
  .billing-requests {
    display: flex;
    align-content: center;
    gap: 5px;
  }
  .grid {
    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: 600;
      color: #000000;
    }
    .label {
      align-content: unset;
      justify-content: flex-end;
    }
  }
}
</style>