<template>
  <v-dialog v-model="dialog"  max-width="90%" width="1000">
    <v-form @submit.prevent="save">
      <v-card class="margin-form-modal">
        <v-card-title>
          {{ $t('sale-margin.margin-manager') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row v-if="marginRule">
            <v-col>
              <v-row class="my-6">
                <v-col>
                  <organization-select
                      v-model="marginRule.organization.id"
                      :label="$t('organization.organization')"
                      :disabled="marginRule.id"
                  />
                </v-col>
              </v-row>
              <table class="margin-form-table" v-if="marginRule.organization.id">
                <thead>
                <tr>
                  <th>{{ $t('sale-margin.scenario') }}</th>
                  <th>{{ $t('sale-margin.margin-type') }}</th>
                  <th>{{ $t('sale-margin.operator-margin') }}</th>
                  <th>{{ $t('sale-margin.roaming-fee') }}</th>
                  <th>{{ $t('sale-margin.payment-processing-fee') }}</th>
                  <th>{{ $t('sale-margin.invoice-payment-fee') }}</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="scenario in marginRule.scenarios">
                  <tr :key="scenario.id" class="line-1">
                    <td class="scenario-name" rowspan="2">
                      {{ $t(`sale-margin.scenarios.${scenario.id}`) }}
                    </td>
                    <td class="margin-type"  rowspan="2">
                      <v-select
                          v-model="scenario.marginType"
                          hide-details
                          outlined
                          :disabled="!canEditField(scenario.id, 'marginType')"
                          :items="[
                        {text: $t('sale-margin.margin-types.commission'), value: 'commission'},
                        {text: $t('sale-margin.margin-types.markup'), value: 'markup'},
                      ]"></v-select>
                    </td>


                    <td>
                      <v-text-field
                          v-if="canEditField(scenario.id, 'operatorFeeFixed')"
                          v-model.number="scenario.operatorFeeFixed"
                          type="number"
                          outlined
                          min="0"
                          hide-details
                          suffix="€"
                      />
                      <span v-else>-</span>
                    </td>


                    <td>
                      <v-text-field
                          v-if="canEditField(scenario.id, 'roamingFeeFixed')"
                          v-model.number="scenario.roamingFeeFixed"
                          type="number"
                          outlined
                          min="0"
                          hide-details
                          suffix="€"
                      />
                      <span v-else>-</span>
                    </td>

                    <td>
                      <v-text-field
                          v-if="canEditField(scenario.id, 'paymentProcessingFeeFixed')"
                          v-model.number="scenario.paymentProcessingFeeFixed"
                          type="number"
                          outlined
                          min="0"
                          hide-details
                          suffix="€"
                      />
                      <span v-else>-</span>
                    </td>


                    <td>
                      <v-text-field
                          v-if="canEditField(scenario.id, 'invoicePaymentFeeFixed')"
                          v-model.number="scenario.invoicePaymentFeeFixed"
                          type="number"
                          outlined
                          min="0"
                          hide-details
                          suffix="€"
                      />
                      <span v-else>-</span>
                    </td>


                  </tr>
                  <tr :key="'2-' + scenario.id"  class="line-2">

                    <td>
                      <v-text-field
                          v-if="canEditField(scenario.id, 'operatorFeePercent')"
                          v-model.number="scenario.operatorFeePercent"
                          type="number"
                          outlined
                          min="0"
                          max="100"
                          hide-details
                          suffix="%"
                      />
                      <span v-else>-</span>
                    </td>

                    <td>
                      <v-text-field
                          v-if="canEditField(scenario.id, 'roamingFeePercent')"
                          v-model.number="scenario.roamingFeePercent"
                          type="number"
                          outlined
                          min="0"
                          max="100"
                          hide-details
                          suffix="%"
                      />
                      <span v-else>-</span>
                    </td>

                    <td>
                      <v-text-field
                          v-if="canEditField(scenario.id, 'paymentProcessingFeePercent')"
                          v-model.number="scenario.paymentProcessingFeePercent"
                          type="number"
                          outlined
                          hide-details
                          min="0"
                          max="100"
                          suffix="%"
                      />
                      <span v-else>-</span>
                    </td>

                    <td>
                      <v-text-field
                          v-if="canEditField(scenario.id, 'invoicePaymentFeePercent')"
                          v-model.number="scenario.invoicePaymentFeePercent"
                          type="number"
                          outlined
                          min="0"
                          max="100"
                          hide-details
                          suffix="%"
                      />
                      <span v-else>-</span>
                    </td>

                  </tr>
                </template>
                </tbody>
              </table>

            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row dense>
            <v-col class="ma-0 text-right">
              <v-btn small @click="close">{{ $t('close') }}</v-btn>
              <v-btn class="ml-4" color="primary" type="submit" small>{{ $t('save') }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import OrganizationSelect from "@blocks/Select/OrganizationSelect.vue";
import MarginRuleRepository from "@repository/MarginRuleRepository";

export default {
  components: {OrganizationSelect},
  data() {
    return {
      dialog: false,
      marginRule: null
    }
  },

  props: {
  },

  watch: {
    dialog(val) {
      if(!val) {
        this.marginRule = null
        this.$emit('closed')
      }
    }
  },

  computed: {
    scenarios() {
      return this.$parent.scenarios
    },
  },

  methods: {
    open(data) {
      this.marginRule = data
      this.dialog = true
    },
    close() {
      this.dialog = false
    },


    canEditField(scenarioId, field) {
      return this.$parent.canEditField(scenarioId, field)
    },


    save() {
      const data = this.cloneObj(this.marginRule)

      if(!data.organization?.id) {
        this.notifyError("Organization is required")
        return;
      }

      data.organizationId = data.organization.id
      delete data.organization

      data.scenarios.map(scenario => {
        for(const a in scenario) {
          if(a.endsWith('Fixed') || a.endsWith('Percent')) {
            scenario[a] = parseFloat(scenario[a])
          }
        }
      })

      if(!this.marginRule.id) {
        MarginRuleRepository.create(data).then(() => {
          this.$parent.loadData()
          this.dialog = false
          this.notifySuccess(this.$t('create-success'))
        }).catch(err => {
          this.notifyError(err)
        })
      } else {
        MarginRuleRepository.update(this.marginRule.id, data).then(data => {
          this.$parent.loadData()
          this.dialog = false
          this.notifySuccess(this.$t('update-success'))
        }).catch(err => {
          this.notifyError(err)
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .margin-form-modal {
    .margin-form-table {
      width: 100% !important;
      border-collapse: collapse;
      tr {
        height: 50px;
        th {
          border-right: 1px solid #ccc;
          font-family: dosis, sans-serif;
          font-size: 18px;
          font-weight: 600;
          color: #5F6569;
          &:last-child {
            border-right: 0;
          }
        }
        td {
          text-align: center;
          vertical-align: middle;
          padding: 3px 10px;
          font-weight: 500;
          .v-text-field {
            .v-input__control {
              min-height: 40px;
              .v-input__slot {
                min-height: 40px;
              }
              input {
                text-align: right;
                &::-webkit-inner-spin-button {
                  margin-left: 20px;
                }
              }
            }
          }
          .v-select__selections {
            padding: 0 !important;
          }
          .v-input__append-inner {
            margin-top: 9px !important;
          }
        }
        &.line-1 {
          border-top: 1px solid #ccc;
          &:first-child {
            border-top: 0;
          }
          td {
            padding-top: 10px;
          }
        }
        &.line-2 {
          td {
            padding-bottom: 10px;
          }
        }
      }
      .scenario-name {
        width: 200px;
        color:  var(--v-primary-base);
        font-weight: bold;
      }
      .margin-type {
        width: 170px;
      }
    }
  }

</style>