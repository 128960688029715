import Vue from 'vue'

class InvoiceRepository {

  controller = null

  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/search',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }



  searchByRecipient(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/search-by-recipient',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }



  searchByNumber(reference) {
    return new Promise((resolve, reject) => {

      if(this.controller) {
        this.controller.abort()
      }
      this.controller = new AbortController()

      Vue.auth.fetch({
        url: '/api/proforma-invoices/search-by-number',
        method: 'post',
        data: {
          reference,
        },
        signal: this.controller.signal
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/get/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  edit(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/edit/' + id,
        method: 'get',
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }


  attachProformaInvoiceToGroup(parentId, proformaInvoiceId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/attach-to-group',
        method: 'post',
        data: {
            parentId,
            proformaInvoiceId,
        }
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  convertToInvoice(proformaInvoiceId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/convert-to-invoice',
        method: 'post',
        data: {
          proformaInvoiceId,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  reject(proformaInvoiceId, comment) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/reject',
        method: 'post',
        data: {
            proformaInvoiceId,
            comment,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  validate(proformaInvoiceId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/validate',
        method: 'post',
        data: {
            proformaInvoiceId,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  restore(proformaInvoiceId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/restore',
        method: 'post',
        data: {
            proformaInvoiceId,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  underReview(proformaInvoiceId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/under-review',
        method: 'post',
        data: {
            proformaInvoiceId,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  deleteGroup(proformaInvoiceGroupId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/proforma-invoices/${proformaInvoiceGroupId}/delete-group`,
        method: 'delete',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  detachProformaInvoiceFromGroup(proformaInvoiceId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/detach-from-group',
        method: 'post',
        data: {
            proformaInvoiceId,
        }
      }).then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  pdf(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/proforma-invoices/pdf/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  inlinePdf(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/pdf/' + id,
        method: 'get',
        responseType: 'arraybuffer'
      })
      .then(response => {
        let blob = new Blob([response.data], { type: response.headers['content-type'] } );
        let url = window.URL.createObjectURL(blob);
        resolve(url)
      }).catch(async err => {
        let blob = new Blob([err.response.data], { type: err.response.headers['content-type'] } );
        err.response.data = JSON.parse(await blob.text())
        reject(err)
      })
    })
  }

  exportCsv(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/proforma-invoices/export',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  downloadAttachment(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/proforma-invoices/attachments/' + id,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateBillingDetails(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/update-billing-details/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateComment(id, comment) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/update-comment/' + id,
        method: 'post',
        data: {
          comment,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  createLine(proformaInvoiceId, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/' + proformaInvoiceId + '/create-line',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  updateLine(proformaInvoiceId, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/' + proformaInvoiceId + '/update-line',
        method: 'put',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  restoreLine(proformaInvoiceId, proformaInvoiceLineId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/' + proformaInvoiceId + '/restore-line',
        method: 'post',
        data: {
          proformaInvoiceLineId,
        },
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  deleteLine(proformaInvoiceId, proformaInvoiceLineId, comment) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/' + proformaInvoiceId + '/delete-line',
        method: 'delete',
        data: {
          proformaInvoiceLineId,
          comment,
        },
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  createProformaInvoicesGroup(proformaInvoiceIds) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/create-group',
        method: 'post',
        data: {
          proformaInvoiceIds,
        },
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  itemLinks(proformaInvoiceId, proformaInvoiceLineId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/proforma-invoices/line-item-links',
        method: 'post',
        data: {
          proformaInvoiceId,
          proformaInvoiceLineId,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  deleteItemLink(proformaInvoiceId, proformaInvoiceLineId, proformaInvoiceItemLinkId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/proforma-invoices/${proformaInvoiceId}/delete-item-link`,
        method: 'delete',
        data: {
          proformaInvoiceLineId,
          proformaInvoiceItemLinkId,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  restoreItemLink(proformaInvoiceId, proformaInvoiceLineId, proformaInvoiceItemLinkId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/proforma-invoices/${proformaInvoiceId}/restore-item-link`,
        method: 'post',
        data: {
          proformaInvoiceLineId,
          proformaInvoiceItemLinkId,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  line(proformaInvoiceId, proformaInvoiceLineId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/proforma-invoices/get/line`,
        method: 'post',
        data: {
          proformaInvoiceId,
          proformaInvoiceLineId,
        }
      })
      .then(response => {
          resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default new InvoiceRepository()
