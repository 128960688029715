<template>
  <div class="pa-10">
    <title-page icon="ico-billingRequest">
      {{ $tc('billing-request.billing-request', 2) }}
    </title-page>


    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row dense>
              <v-col>
                <date-picker
                    label="Date de création"
                    v-model="rangeDate"
                    clearable
                    hide-details
                    range
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <organization-select
                    :label="$t('organization.organization')"
                    clearable
                    v-model="filters.organizationId"
                />
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>



    <v-divider class="my-6"/>
    <v-row>
      <v-col>
        <v-tabs centered>
          <v-tab :to="{name: 'billing-requests'}">{{ $tc('billing-request.billing-request', 2)}}</v-tab>
          <v-tab :to="{name: 'billing-requests.invoices'}">{{ $tc('billing-request-invoice.billing-request-invoice', 2)}}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-divider class="my-6"/>


    <v-row class="align-center mb-2">
      <v-col>
        <search-field  clearable></search-field>
      </v-col>
    </v-row>


    <v-row>
      <v-col >
        <v-data-table
          :headers="headers"
          :items="billingRequestInvoices"
          :options.sync="options"
          :loading="loading"
          :server-items-length="total"
        >
          <template #item.createdAt="{item}">
            {{ item.createdAt | formatDate($t('format_date')) }}
          </template>


          <template #item.netExceptedAmount="{item}">
            {{ item.netExceptedAmount ? numberFormat(item.netExceptedAmount) + ' €' : '-' }}
          </template>

          <template #item.status="{item}">
            {{ $t('billing-request-invoice.statues.' + item.status) }}
          </template>


          <template #item.options="{item}">
            <v-btn icon small :to="{name: 'billing-requests.invoices.show', params: {id: item.id}}">
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import OrganizationSelect from "@blocks/Select/OrganizationSelect.vue";
import BillingRequestRepository from "@repository/BillingRequestRepository";

export default {
  components: {
    OrganizationSelect,
  },
  data() {
    return {
      loading: false,
      rangeDate: [],
      filters: {
        organizationId: null,
      },
      total: 0,
      billingRequestInvoices: [],
      options: null,
    }
  },

  mounted() {

  },

  watch: {
    filters: {
      handler() {
        if(this.options.page > 1) {
          this.options.page = 1
        } else {
          this.loadData()
        }
      },
      deep: true
    },


    rangeDate() {
      this.loadData()
    },

    options() {
      this.loadData()
    },

  },

  computed: {

    headers() {
      return [
        {text: this.$t('organization.organization'), value: 'organization.name'},
        {text: this.$t('billing-request.reference'), value: 'reference'},
        {text: this.$t('price-ht'), value: 'netExceptedAmount'},
        {text: this.$t('status'), value: 'status'},
        {text: this.$t('created-at'), value: 'createdAt'},
        {value: 'options'},
      ]
    },

  },

  methods: {

    loadData() {

      this.loading = true
      let data = {
        ...this.filters,
      }


      if(this.rangeDate?.length > 0) {
        data.startDate = this.rangeDate[0]
        data.endDate = this.rangeDate[1]
      }



      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      if (this.options.sortBy.length > 0) {
        data.sortBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc'
      }


      BillingRequestRepository.searchInvoices(data).then(response => {
        this.total = response.total
        this.billingRequestInvoices = response.items
        this.loading = false
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.loading = false
        this.showLoading(false)
      })
    },


    onBillingRequestInvoiceUpdated(data) {
      this.loadData()
    },


  },

}
</script>

<style lang="scss">
.br-table {
  tr {
    &:first-child {
      th {
        &:first-child {
          i {
            padding-left: 10px;
          }

          .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
            background-color: transparent !important;
          }
        }
      }
    }
  }


}

</style>
