import Vue from 'vue'

class OperatorRepository {

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/operator/get/' + id,
        method: 'get',
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  create(operator) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/operator',
        method: 'post',
        data: operator,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  update(id, operator) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/operator/' + id,
        method: 'put',
        data: operator,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  search(query = '', limit = null, offset = null) {
    return new Promise((resolve, reject) => {

      let data = {
        query
      }

      if(data.limit !== null) {
        data.limit = limit
      }

      if(data.offset !== null) {
        data.offset = offset
      }

      Vue.auth.fetch({
        url: '/api/roaming/operator/search',
        method: 'get',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default new OperatorRepository()