<template>
  <div>
    <highcharts
        class="chart"
        :options="options"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },

  props: {
    label: String,
    data: Object,
  },

  computed: {
    options() {

      return {
        accessibility: {
          enabled: false,
        },
        chart: {
          type: 'pie',
          height: 200
        },
        title: {
          text: this.label
        },
        tooltip: {
          valueSuffix: this.data.tooltipValueSuffix
        },

        credits: false,
        plotOptions: {
          series: {
            allowPointSelect: false,
            dataLabels: {
              allowOverlap: true,
              enabled: true,
              crop: false,
              overflow: "none",
              align: 'left',
              x: -2,
              y: -5,
              style: {
                color: 'black',
                font: '10px Arial, sans-serif',
                fontWeight: 'normal',
              },
            }
          }
        },
        series: this.data?.series ?? []
      }
    },


  }
}
</script>

<style lang="scss">

</style>