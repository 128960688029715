<template>
  <div class="pa-10 show-billing-request-invoice">
    <title-page icon="ico-billingRequest">
      {{ $t('billing-request-invoice.invoice-transmission') }}
    </title-page>

    <v-breadcrumbs
        :items="[
            {text: $tc('billing-request-invoice.billing-request-invoice', 2), to: {name: 'billing-requests.invoices'}, exact: true}
        ]"
        divider=">"
    ></v-breadcrumbs>

    <div class="grid pt-0">

      <headline>
        {{ $tc('invoice.invoice')}}
      </headline>


      <v-row dense>
        <v-col class="label">
          {{ $t('date')}}
        </v-col>
        <v-col>
          {{ formatDate(billingRequestInvoice.createdAt, $t('format_date')) }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col class="label">
          {{ $t('billing-request-invoice.invoice-reference')}}
        </v-col>
        <v-col>
          {{ billingRequestInvoice?.reference ? billingRequestInvoice.reference : '-' }}
        </v-col>
      </v-row>

      <v-row dense class="justify-center" v-if="isDraft && !billingRequestInvoice.reference">
        <v-col cols="6">
          <v-alert dense type="warning">
            {{ $t('billing-request-invoice.invoice-reference-required')}}
          </v-alert>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col class="label">
          {{ $t('status')}}
        </v-col>
        <v-col>
          {{ $t('billing-request-invoice.statues.' + billingRequestInvoice.status) }}
        </v-col>
      </v-row>

      <template v-if="billingRequestInvoice.reviewFeedback">
        <div class="font-weight-light mt-5">
          {{ $t('billing-request-invoice.review-feedback') }} :
        </div>
        <v-alert>
          {{ billingRequestInvoice.reviewFeedback}}
        </v-alert>

        <v-divider></v-divider>
      </template>




      <v-row dense>
        <v-col class="label">
          {{ $t('created-by')}}
        </v-col>
        <v-col>
          {{ billingRequestInvoice.createdBy?.firstname }} {{ billingRequestInvoice.createdBy?.lastname }}
        </v-col>
      </v-row>



      <headline class="mt-10">
        {{ $t('organization.organization')}}
      </headline>
      <v-row dense>
        <v-col class="label">
          {{ $t('name')}}
        </v-col>
        <v-col>
          <template>
            {{ billingRequestInvoice.organization.name ?? '-' }}
          </template>
        </v-col>
      </v-row>







      <headline class="mt-10">
        {{ $tc('billing-request-invoice.linked-billing-request', 2)}}
      </headline>
      <v-row dense>
        <v-col class="align-center">
          <div class="billing-requests">
            <v-chip @click="openBillingRequest(billingRequest.id)" v-for="billingRequest in billingRequestInvoice.billingRequests" :key="billingRequest.id">
              {{ billingRequest.billingRequestReference }}
            </v-chip>
          </div>

        </v-col>
      </v-row>


      <headline class="mt-10">
        {{ $t('amount') }}
      </headline>
      <v-row dense>
        <v-col class="label">
          {{ $t('billing-request-invoice.net-expected-amount')}}
        </v-col>
        <v-col>
          {{ netExceptedAmount ? numberFormat(netExceptedAmount) + ' €' : '-' }}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="label">
          {{ $t('billing-request-invoice.total-expected-amount')}}
        </v-col>
        <v-col>
          {{ totalExceptedAmount ? numberFormat(totalExceptedAmount) + ' €' : '-' }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col class="label">
          {{ $tc('attachment')}}
        </v-col>
        <v-col>
          <a href="#" @click.prevent="downloadFile()" v-if="billingRequestInvoice.file">
            {{ billingRequestInvoice.file.filename }}
          </a>
          <template v-else>
            {{ $t('billing-request-invoice.no-attachment')}}
          </template>
        </v-col>
      </v-row>

      <v-row dense class="justify-center" v-if="isDraft && !billingRequestInvoice.file">
        <v-col cols="6">
          <v-alert dense type="warning">
            {{    $t('billing-request-invoice.attachment-required') }}
          </v-alert>
        </v-col>
      </v-row>
    </div>






    <template v-if="isDraft">
      <headline class="mt-10">
        {{ $t('actions') }}
      </headline>
      <v-row justify="center" class="gap-4">
        <v-btn outlined small :to="{name: 'billing-requests.invoices.edit', params: {id}}">{{ $t('billing-request-invoice.invoice-edit')}}</v-btn>
        <v-btn color="error" outlined small @click="cancelInvoice" >{{ $t('billing-request-invoice.invoice-cancel')}}</v-btn>
        <v-btn color="success" :disabled="!canValidate"  @click="submitInvoiceForReview" outlined small>{{ $t('billing-request-invoice.invoice-send')}}</v-btn>
      </v-row>
      <v-row>
        <v-col class="text-center font-weight-light">
          {{ $t('billing-request-invoice.draft-invoice-notes')}}
        </v-col>
      </v-row>
    </template>






    <template v-if="isValidatorUser">
      <template v-if="billingRequestInvoice.status === 'waiting-for-review'">
        <headline class="mt-10">
          {{ $t('actions') }}
        </headline>
        <v-row>
          <v-col class="text-center">
            Veuillez vérifier les informations ci-dessus et choisissez une action
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="font-weight-light">
              {{ $t('billing-request-invoice.review-feedback') }} :
            </div>
            <v-textarea
                solo
                auto-grow
                rows="4"
                v-model="reviewFeedback"
                :placeholder="$t('billing-request-invoice.review-feedback-placeholder')"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center">
            <v-btn class="ml-3" @click="acceptInvoice"  small color="success">{{ $t('billing-request-invoice.accept-invoice')}}</v-btn>
            <v-btn class="ml-3"  @click="rejectInvoice" small color="error">{{ $t('billing-request-invoice.reject-invoice')}}</v-btn>
          </v-col>
        </v-row>


      </template>
    </template>



    <billing-request-show-modal ref="billingRequestModal" />
  </div>
</template>

<script>
import BillingRequestRepository from "@repository/BillingRequestRepository";
import BillingRequestShowModal from "@modals/BillingRequestShowModal.vue";
import RulesMixin from "@mixins/RulesMixin";

export default {
  components: {
    BillingRequestShowModal,
  },

  data() {
    return {
      form: {
        billingRequests: [],
      },
      reviewFeedback: null,
      dataChanged: false,
      invoiceFile: null,
      billingRequestInvoice: null,
    }
  },

  mixins: [
    RulesMixin,
  ],


  mounted() {
    this.loadData()
  },

  watch: {

  },

  computed: {

    id() {
      return this.$route.params.id
    },

    isValidatorUser() {
      return this.currentUser.isZE
    },

    isDraft() {
      return this.billingRequestInvoice.status === 'draft'
    },

    canValidate() {
      return this.isValidatorUser &&
          this.isDraft &&
          this.billingRequestInvoice.billingRequests.length > 0 &&
          this.billingRequestInvoice.reference &&
          this.billingRequestInvoice.file
    },

    netExceptedAmount() {
      return this.billingRequestInvoice.billingRequests.reduce((acc, item) => acc + item.netTotal, 0)
    },

    totalExceptedAmount() {
      return this.billingRequestInvoice.billingRequests.reduce((acc, item) => acc + item.finalTotal, 0)
    },

  },

  methods: {

    openBillingRequest(billingRequestId) {
      this.$refs.billingRequestModal.open(billingRequestId)
    },

    loadData() {
      return new Promise((resolve, reject) => {
        this.showLoading(true)
        BillingRequestRepository.getInvoice(this.id).then(data => {
          this.billingRequestInvoice = data
          this.showLoading(false)
          resolve(data)
        }).catch(error => {
          this.notifyError(error)
          this.showLoading(false)
          reject(error)
        })
      })
    },


    downloadFile() {
      this.showLoading(true)
      BillingRequestRepository.downloadInvoiceFile(this.billingRequestInvoice.id).then(data => {
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    deleteBillingRequest(id) {
      this.form.billingRequests = this.form.billingRequests.filter(item => item.id !== id)
    },

    openSelectBillingRequestModal() {
      this.$refs.selectBillingRequestModal.open()
    },

    submitInvoiceForReview() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          BillingRequestRepository.submitInvoiceForReview(this.id).then(data => {
            this.showLoading(false)
            this.loadData()
          }).catch(err => {
            this.showLoading(false)
            this.notifyError(err)
          })
        }
      })
    },

    cancelInvoice() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          BillingRequestRepository.cancelInvoice(this.id).then(data => {
            this.showLoading(false)
            this.$router.push({name: 'billing-requests.invoices'})
          }).catch(err => {
            this.showLoading(false)
            this.notifyError(err)
          })
        }
      })
    },

    acceptInvoice() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          BillingRequestRepository.acceptInvoice(this.id, this.reviewFeedback).then(data => {
            this.showLoading(false)
            this.loadData()
          }).catch(err => {
            this.showLoading(false)
            this.notifyError(err)
          })
        }
      })
    },

    rejectInvoice() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          BillingRequestRepository.rejectInvoice(this.id, this.reviewFeedback).then(data => {
            this.showLoading(false)
            this.loadData()
          }).catch(err => {
            this.showLoading(false)
            this.notifyError(err)
          })
        }
      })
    }

  }
}
</script>



<style lang="scss">
.show-billing-request-invoice {
  .billing-requests {
    display: flex;
    align-content: center;
    gap: 5px;
  }
  .grid {
    .col {
       display: flex;
       flex-direction: column;
       justify-content: center;
       font-weight: 600;
       color: #000000;
     }
    .label {
      align-content: unset;
      justify-content: flex-end;
    }
  }
}
</style>