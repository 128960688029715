<template>
  <div class="proforma-invoice-edit-form" v-if="proformaInvoice">
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <v-row v-for="section in sections" :key="section.label">
              <v-col>
                <headline>
                  {{ section.label }}
                  <v-btn class="right" small @click="openCreateLineModal(section)" v-if="canEdit">{{ $t('proforma-invoice.create-line')}}</v-btn>
                </headline>
                <v-row>
                  <v-col class="font-weight-light text-right" v-if="proformaInvoice.parentId">
                    Période du {{ formatUtcDate(section.period?.start,'DD/MM/YYYY') }} au {{ formatUtcDate(section.period?.end,'DD/MM/YYYY') }}
                  </v-col>
                </v-row>
                <v-simple-table class="proforma-invoice-lines">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th width="100" class="text-center">&nbsp;</th>
                      <th width="100" class="text-center">{{ $t('proforma-invoice.product-code') }}</th>
                      <th>{{ $t('label') }}</th>
                      <th width="40" class="text-center">{{ $t('proforma-invoice.quantity') }}</th>
                      <th width="100" class="text-right">{{ $t('proforma-invoice.unit-price') }}</th>
                      <th width="100" class="text-right">{{ $t('proforma-invoice.vat-rate') }}</th>
                      <th width="100" class="text-center">{{ $t('country') }}</th>
                      <th width="150" class="text-right">{{ $t('proforma-invoice.total-ht') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(type, t) in section.types">
                      <tr :key="section.label + '::' + t">
                        <td colspan="8" class="proforma-invoice-line-type">{{ $t('proforma-invoice.line-types.' + type.label) }}</td>
                      </tr>
                      <template  v-for="line in type.lines">
                        <tr :key="line.id" :class="{'deleted': line.corrective?.correctiveType === 'delete'}">
                          <td class="btns">
                            <div class="text-center">
                              <v-btn small icon @click="openEditLineModal(line)"  v-if="canEdit">
                                <v-icon small>mdi-pencil</v-icon>
                              </v-btn>
                              <v-tooltip color="blue" dark bottom v-if="line.isCorrected">
                                <template v-slot:activator="{ on }">
                                  <v-icon small v-on="on" color="blue">mdi-information</v-icon>
                                </template>
                                <div>Modifié par <b>{{ line.corrective.updatedBy.firstname }} {{ line.corrective.updatedBy.lastname}}</b> le {{ line.corrective.updatedAt|formatDate }}</div>
                                <v-divider class="white"></v-divider>
                                <div v-html="nl2br(line.corrective.comment)"></div>
                              </v-tooltip>
                            </div>
                          </td>
                          <td class="text-center">{{ line.productCode }}</td>
                          <td>
                            <div><b>{{ line.label }}</b></div>
                            <div v-for="(descLine, i) in line.description?.split('\n')" :key="line.id + '::' + i">{{ descLine }}</div>
                          </td>
                          <td class="text-center">{{ line.quantity }}</td>
                          <td class="text-right">{{ numberFormat(line.unitPrice) }} €</td>
                          <td class="text-right">{{ !line.isDisbursement ? (line.selfLiquidation ? $t('proforma-invoice.self-liquidation') : line.vatRate + ' %') : '' }}</td>
                          <td class="text-center">{{ !line.isDisbursement ? line.country : '' }}</td>
                          <td class="text-right ">{{ numberFormat(line.netTotal) }} €</td>
                        </tr>
                      </template>
                    </template>

                    <tr class="total">
                      <td colspan="6" class="no-border"></td>
                      <td class="text-center">{{ $t('proforma-invoice.total-ht') }}</td>
                      <td class="text-right font-weight-bold">{{ numberFormat(section.total) }} €</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>



          </v-col>
        </v-row>
      </v-col>
    </v-row>


    <v-dialog v-model="editLineModal" v-if="selectedLine" max-width="1000" scrollable>
      <v-card>
        <v-card-title>
          {{ $t('proforma-invoice.edit-line') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>

          <input type="text" v-show="false">
          <v-row class="mt-4">
            <v-col>
              <v-row>
                <v-col>
                  <form-field :readonly="!canEditFields" :label="$t('proforma-invoice.line-number')" v-model.number="selectedLine.lineNumber" input-type="number" />
                </v-col>
                <v-col>
                  <form-field :readonly="!canEditFields" :label="$t('proforma-invoice.product-code')" v-model="selectedLine.productCode" col-field="3"  />
                </v-col>
              </v-row>
              <form-field v-if="false" :readonly="!canEditFields" :label="$t('proforma-invoice.section')" v-model="selectedLine.section" col-field="9" />
              <form-field v-if="false" :readonly="!canEditFields" :label="$t('proforma-invoice.type')" v-model="selectedLine.type"  type="select" :items="typeOptions" col-field="9"   />
              <form-field v-if="canEditPrice" :readonly="!canEditFields" :label="$t('label')" v-model="selectedLine.label" col-field="9" />
              <form-field v-if="canEditPrice" :readonly="!canEditFields" :label="$t('description')" type="textarea" v-model="selectedLine.description"  col-field="9" />

              <v-row>
                <v-col cols="6">
                  <form-field :readonly="!canEditFields || !canEditPrice" :disabled="!canEditFields || !canEditPrice" :label="$t('proforma-invoice.quantity')" v-model.number="selectedLine.quantity" input-type="number" />
                </v-col>
                <v-col>
                  <form-field :readonly="!canEditFields || !canEditPrice" :disabled="!canEditFields || !canEditPrice" :label="$t('proforma-invoice.unit-price')" v-model.number="selectedLine.unitPrice" input-type="number" />
                </v-col>
                <v-col>
                  <form-field :readonly="!canEditFields" :label="$t('country')" v-model="selectedLine.country" type="available-select" input-type="country" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <form-field :readonly="!canEditFields" :label="$t('invoice.is-disbursement')" type="switch" v-model="selectedLine.isDisbursement" />
                </v-col>
                <v-col>
                  <form-field :readonly="!canEditFields" :label="$t('proforma-invoice.self-liquidation')" type="switch" v-model="selectedLine.selfLiquidation" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <template v-if="lineItemLinks.length > 0">
            <v-divider class="my-6"></v-divider>
            <v-row>
              <v-col>
                <v-data-table
                  class="item-links"
                  :items="lineItemLinks"
                  :headers="[
                      {text: 'data', value: 'data'},
                      {text: $t('quantity'), value: 'quantity'},
                      {text: $t('price'), value: 'price'},
                      {text: '', value: 'options'},
                  ]"
                >
                  <template #item="{item}">
                    <tr :class="{delete: correctiveItemLinkForId(item.id)?.correctiveType === 'delete'}" :key="item.id">
                      <td class="text-left">
                        <template v-if="item.linkType === 'expense-period'">
                          Remboursement effectuée sur la période
                        </template>
                        <template v-else-if="item.data?.related">
                          {{ item.data.related.name }} ({{ item.data.related.type }})
                        </template>
                        <template v-else-if="item.data?.chargingPoint">
                          {{ item.data.startTime | formatDate('DD/MM/YYYY') }} : {{ item.data.chargingPoint.name }} ({{ item.data.energy/1000 }}  {{ $t('usages.kwh') }})
                        </template>
                        <template v-else-if="item.linkType === 'cdr'">
                          <div>
                            {{ item.data.startTime | formatDate('DD/MM/YYYY') }} : {{ item.data.energy/1000 }} {{ $t('usages.kwh') }}
                          </div>
                        </template>
                        <template v-else>
                          {{ item.data }}
                        </template>
                      </td>
                      <td class="text-center">
                        {{ item.quantity }}
                      </td>
                      <td class="text-center">
                        {{ (item.price * item.quantity)?.toFixed(2) }} €
                      </td>
                      <td class="text-center">
                        <template v-if="canEditFields">
                          <v-btn type="button" small icon @click="deleteItemLink(item)" v-if="correctiveItemLinkForId(item.id)?.correctiveType !== 'delete'">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn type="button" small icon @click="restoreItemLink(item)" v-if="correctiveItemLinkForId(item.id)?.correctiveType === 'delete'">
                            <v-icon>mdi-undo</v-icon>
                          </v-btn>
                        </template>
                      </td>
                    </tr>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </template>
          <v-divider class="my-6"></v-divider>
          <v-row>
            <v-col>
              <form-field :label="$t('comment')" type="textarea" v-model="selectedLine.comment"  col-field="9" rows="3" :placeholder="$t('proforma-invoice.edit-line-comment-placeholder')" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-2"></v-divider>
        <v-card-actions>
          <v-btn v-if="selectedLine.corrective" small text @click="restoreLine(selectedLine)">
            {{ $t('proforma-invoice.restore-line') }}
          </v-btn>
          <v-btn v-if="selectedLine.corrective?.correctiveType !== 'delete'" small text @click="openDeleteLineModal(selectedLine)">
            {{ $t('delete') }}
          </v-btn>
          <v-spacer></v-spacer>
          <template v-if="canEditFields" >
            <v-btn small text @click="editLineModal = false">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn small color="primary" @click="saveLine">
              {{ $t('save') }}
            </v-btn>
          </template>
          <template v-else >
            <v-btn small text @click="editLineModal = false">
              {{ $t('close') }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="deleteLineModal" v-if="selectedLineToDelete" max-width="1000">
      <v-card>
        <v-card-title>
          {{ $t('proforma-invoice.delete-line') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <form-field :label="$t('comment')" type="textarea" v-model="selectedLineToDelete.comment"  col-field="9" rows="3" :placeholder="$t('proforma-invoice.delete-line-comment-placeholder')" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-2"></v-divider>
        <v-card-actions>
          <v-btn small text @click="deleteLineModal = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn small color="primary" @click="deleteLine(selectedLineToDelete)">
            {{ $t('delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProformaInvoiceRepository from "@repository/ProformaInvoiceRepository";

export default {
  components: {

  },

  data() {
    return {
      selectedLine: null,
      selectedLineToDelete: null,
      editLineModal: false,
      deleteLineModal: false,
      lineItemLinks: [],
    }
  },

  props: {
    proformaInvoice: Object,
    countriesFilter: [],
    canEdit: Boolean,
  },

  mounted() {

  },

  watch: {

  },

  computed: {

    id() {
      return this.proformaInvoice.id
    },

    proformaInvoiceLinesCleaned() {
      const lines = this.proformaInvoice.lines
      return lines.map(line => {
        const corrective = line?.corrective
        if(corrective) {
          if(corrective?.correctiveType !== 'delete') {
            line.lineNumber = corrective.lineNumber
            line.productCode = corrective.productCode
            line.section = corrective.section
            line.type = corrective.type
            line.label = corrective.label
            line.description = corrective.description
            line.quantity = corrective.quantity
            line.unitPrice = corrective.unitPrice
            line.country = corrective.country
            line.netTotal = corrective.netTotal
            line.vatRate = corrective.vatRate
            line.valueAddedTax = corrective.valueAddedTax
            line.total = corrective.total
            line.selfLiquidation = corrective.selfLiquidation
            line.isDisbursement = corrective.isDisbursement
            line.comment = corrective.comment
            return line
          }
        }
        return line
      })
    },

    sections() {
      const sections = {}
      for(let item of this.proformaInvoiceLinesCleaned) {
        if(!sections[item.section]) {
          sections[item.section] = {
            label: item.section,
            period: {
              start: item.beginDate,
              end: item.endDate,
            },
            types: {},
            total: 0,
          }
        }
        if(!sections[item.section].types[item.type]) {
          sections[item.section].types[item.type] = {
            label: item.type,
            lines: [],
            total: 0
          }
        }
        if(item.corrective?.correctiveType !== 'delete') {
          sections[item.section].total += item.netTotal
        }
        sections[item.section].types[item.type].lines.push(item)
        sections[item.section].types[item.type].total += item.netTotal
      }


      const finalSections = []
      for(let section in sections) {

        const newSection = {
          label: sections[section].label,
          period: sections[section].period,
          types: [],
          total: sections[section].total
        }

        for(const type in sections[section].types) {
          newSection.types.push(sections[section].types[type])
        }

        finalSections.push(newSection)
      }

      return finalSections
    },

    canEditFields() {
      return this.selectedLine.corrective?.correctiveType !== 'delete'
    },

    canEditPrice() {
      return this.selectedLine.creation || this.selectedLine.corrective?.correctiveType === 'add'
    },

    usedCountries() {
      let usedCountries = {}
      for(let line of this.proformaInvoiceLinesCleaned) {
        usedCountries[line.country] = true
      }
      return Object.keys(usedCountries)
    },

    typeOptions() {
      return [
        {text: this.$t('proforma-invoice.line-types.subscription-formula'), value: 'subscription-formula'},
        {text: this.$t('proforma-invoice.line-types.subscription-option'), value: 'subscription-option'},
        {text: this.$t('proforma-invoice.line-types.subscription-other'), value: 'subscription-other'},
      ]
    }

  },

  methods: {

    loadData() {
      return this.$parent.loadData()
    },

    openCreateLineModal(section) {
      this.lineItemLinks = []
      this.selectedLine = {
        creation: true,
        id:  null,
        proformaInvoiceLineId: null,
        lineNumber: 999,
        productCode: null,
        section: section.label,
        type: "subscription-other",
        label: '',
        description: '',
        quantity: 1,
        unitPrice: 0,
        country: 'FR',
        isDisbursement: false,
        selfLiquidation: false,
        isQuantifiable: true,
        comment: null,
      }
      this.editLineModal = true
    },


    openEditLineModal(line) {
      this.selectedLine = {
        id:  line.corrective?.id,
        proformaInvoiceLineId: line.id,
        lineNumber: line.corrective?.lineNumber ?? line.lineNumber,
        productCode: line.corrective?.productCode ?? line.productCode,
        section: line.corrective?.section ?? line.section,
        type: line.corrective?.type ?? line.type,
        label: line.corrective?.label ?? line.label,
        description: line.corrective?.description ?? line.description,
        quantity: line.corrective?.quantity ?? line.quantity,
        unitPrice: line.corrective?.unitPrice ?? line.unitPrice,
        country: line.corrective?.country ?? line.country,
        isDisbursement: !this.isNullOrUndefined(line.corrective?.isDisbursement) ? line.corrective?.isDisbursement : line.isDisbursement,
        isQuantifiable: line.isQuantifiable,
        selfLiquidation: !this.isNullOrUndefined(line.corrective?.selfLiquidation) ? line.corrective?.selfLiquidation : line.selfLiquidation,
        comment: line.corrective?.comment,
        corrective: line.corrective,
      }
      this.lineItemLinks = []
      if(this.selectedLine.corrective?.correctiveType !== 'add') {
        this.loadItemLinks(this.selectedLine.id)
      }
      this.editLineModal = true
    },


    saveLine() {


      let data = this.cloneObj(this.selectedLine)
      data.itemLinks = data.corrective?.itemLinks ?? []

      if(this.selectedLine.creation || data.corrective?.correctiveType === 'add') {
        data.unitPrice = Number(data.unitPrice)
      } else {
        delete data.unitPrice
        delete data.quantity
      }
      delete data.corrective

      if(!this.selectedLine.comment?.trim()) {
        this.$dialog.notify.warning(this.$t('proforma-invoice.line-edition-comment-required'))
      } else {
        this.showLoading(true);
        let action = ProformaInvoiceRepository.updateLine
        if(this.selectedLine.creation) {
          action = ProformaInvoiceRepository.createLine
        }
        action(this.id, data).then(response => {
          this.loadData()
          this.editLineModal = false
        }).catch(error => {
          this.showLoading(false);
          this.notifyError(error)
        })
      }
    },

    restoreLine(item) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if(result) {
          this.showLoading(true);
          ProformaInvoiceRepository.restoreLine(this.id, item.proformaInvoiceLineId).then(response => {
            this.editLineModal = false
            this.loadData()
          }).catch(error => {
            this.showLoading(false);
            this.notifyError(error)
          })
        }
      })
    },

    openDeleteLineModal(line) {
      this.editLineModal = false
      this.lineItemLinks = []
      if(line.corrective?.correctiveType === 'add') {
        this.deleteLine(line)
        return
      }
      this.selectedLineToDelete = {
        proformaInvoiceLineId: line.proformaInvoiceLineId,
        comment: "",
      }
      this.deleteLineModal = true
    },

    deleteLine(item) {
      if(!item.comment?.trim() && item.corrective?.correctiveType !== 'add') {
        this.$dialog.notify.warning(this.$t('proforma-invoice.line-edition-comment-required'))
      } else {
        this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
          if(result) {
            this.showLoading(true);
            ProformaInvoiceRepository.deleteLine(this.id, item.proformaInvoiceLineId, item.comment).then(response => {
              this.loadData()
              this.selectedLine = null
              this.deleteLineModal = false
            }).catch(error => {
              this.showLoading(false);
              this.notifyError(error)
            })
          }
        })
      }
    },

    filterCountries(country) {
      return this.countriesFilter.includes(country.code) || this.usedCountries.includes(country.code)
    },


    loadItemLinks(lineId) {
      this.showLoading(true)
      this.lineItemLinks = []
      ProformaInvoiceRepository.itemLinks(this.id, lineId).then(data => {
        this.lineItemLinks = data
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    deleteItemLink(item) {
      if(!this.selectedLine.corrective?.itemLinks) {
        this.selectedLine.corrective = {
          itemLinks: [],
        }
      }
      this.selectedLine.corrective.itemLinks.push({
        proformaInvoiceItemLinkId: item.id,
        correctiveType: 'delete',
      })

      if(this.selectedLine.isQuantifiable) {
        this.selectedLine.quantity = (Number(this.selectedLine.quantity) - item.quantity).toFixed(0)
      } else {
        const itemPrice = item.quantity * item.price
        this.selectedLine.unitPrice = (this.selectedLine.unitPrice - itemPrice).toFixed(2)
      }

    },

    restoreItemLink(item) {
      if(this.selectedLine.corrective.itemLinks) {
        this.selectedLine.corrective.itemLinks = this.selectedLine.corrective.itemLinks.filter(itemLink => itemLink.proformaInvoiceItemLinkId !== item.id)
      }

      if(this.selectedLine.isQuantifiable) {
        this.selectedLine.quantity = (Number(this.selectedLine.quantity) + item.quantity).toFixed(0)
      } else {
        const itemPrice = item.quantity * item.price
        this.selectedLine.unitPrice = (Number(this.selectedLine.unitPrice) + itemPrice).toFixed(2)
      }

    },

    correctiveItemLinkForId(itemLinkId) {
      return this.selectedLine?.corrective?.itemLinks.find(itemLink => itemLink.proformaInvoiceItemLinkId === itemLinkId)
    },

  },

}
</script>

<style lang="scss">
  .item-links {
    tr {
      &.delete, &.delete:hover {
        color: red !important;
        text-decoration: line-through;
        .v-icon {
          color: red !important;
        }
      }
      &.modified, &.modified:hover {
        color: blue !important;
        .v-icon {
          color: blue !important;
        }
      }

    }
  }
</style>