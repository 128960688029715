<template>
  <v-dialog v-model="showModal" max-width="800">
    <v-form ref="form" @submit.prevent="sendCreateInvoice">
      <v-card>
        <v-card-title>
          <h3>Envoyer une facture</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-3">
          <v-row>
            <v-col>
              Vous avez sélectionné <b>{{ selectedItems.length }}</b> demandes de facturation :
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div style="max-height: 300px; padding-top: 8px; overflow: scroll; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc">
                <v-chip class="ml-1 mb-2" :key="item.billingRequestReference" close v-for="(item, i) in selectedItems" @click:close="removeSelectedItem(i)">{{ item.billingRequestReference}}</v-chip>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>

              <organization-select
                  v-model="organizationId"
                  label="Organisation"
                  disabled
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>

              <v-text-field
                  v-model="reference"
                  :label="$t('invoice.reference')"
                  clearable
              />
            </v-col>
          </v-row>



          <v-row>
            <v-col>
              Le montant attendu de la facture correspondant à ces demandes de facturation doit être égal à <b>{{ totalForSelectedItems }} € HT</b>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-file-input
                  v-model="invoiceFile"
                  label="Fichier de la facture"
              ></v-file-input>
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row dense>
            <v-col class="ma-0 text-right">
              <v-btn small @click="showModal = false">{{ $t('cancel') }}</v-btn>
              <v-btn class="ml-3" :disabled="!canSend" small color="primary" type="submit">{{ $t('billing-request.transmit-invoice')}}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import BillingRequestRepository from "@repository/BillingRequestRepository";
import OrganizationSelect from "@blocks/Select/OrganizationSelect.vue";
import RulesMixin from "@mixins/RulesMixin";

export default {
  components: {
    OrganizationSelect,
  },

  data() {
    return {
      organizationId: null,
      reference: null,
      invoiceFile: null,
      showModal: false,
    }
  },

  props: {
    selectedItems: Array,
  },

  mixins: [
    RulesMixin,
  ],
  mounted() {

  },

  watch: {

  },

  computed: {

    totalForSelectedItems() {
      return this.numberFormat(this.selectedItems.reduce((acc, item) => acc + item.netTotal, 0))
    },

    canSend() {
      return this.selectedItems.length > 0
    }
  },

  methods: {
    open() {
      let organizationIds = this.selectedItems.map(item => item.organizationId)
      this.organizationId = organizationIds[0] ?? null
      this.reference = null
      this.invoiceFile = null
      if(!this.organizationId) {
        this.organizationId = this.currentUser?.selectedOrganizationId ?? this.currentUser?.organizations[0] ?? null
      }
      this.showModal = true
    },

    close() {
      this.showModal = false
    },

    removeSelectedItem(index) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.selectedItems.splice(index, 1)
          if(this.selectedItems.length === 0) {
            this.close()
          }
        }
      })
    },

    sendCreateInvoice() {

      if(this.$refs.form.validate()) {
        this.showLoading(true)
        BillingRequestRepository.createBillingDetailInvoice(
            this.organizationId,
            this.reference,
            this.selectedItems.map(item => item.id),
            this.invoiceFile,
        ).then(data => {
          this.notifySuccess(this.$t('billing-request.invoice-sent'))
          this.showLoading(false)
          this.close()
        }).catch(err => {
          this.notifyError(err)
          this.showLoading(false)
        })
      }

    }
  }
}
</script>



<style lang="scss">

</style>