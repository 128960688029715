import Vue from 'vue'

class BillingRequestRepository {

  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/search',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  searchInvoices(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/invoices/search',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/get/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  pdf(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/billing-requests/pdf/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  inlinePdf(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/pdf/' + id,
        method: 'get',
        responseType: 'arraybuffer'
      })
      .then(response => {
        let blob = new Blob([response.data], { type: response.headers['content-type'] } );
        let url = window.URL.createObjectURL(blob);
        resolve(url)
      }).catch(async err => {
        let blob = new Blob([err.response.data], { type: err.response.headers['content-type'] } );
        err.response.data = JSON.parse(await blob.text())
        reject(err)
      })
    })
  }


  exportCsv(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/billing-requests/export',
        method: 'post',
        data,
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  downloadAttachment(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/billing-requests/attachments/' + id,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateBillingDetails(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/update-billing-details/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  createBillingDetailInvoice(organizationId, reference, billingRequests, invoiceFile) {

    const data = new FormData()
    data.append('content', JSON.stringify({organizationId, reference, billingRequests}))
    data.append('invoiceFile', invoiceFile)

    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/create-invoice',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateBillingDetailInvoice(billingRequestInvoiceId, reference, billingRequestIds, invoiceFile, fileDeleted) {

    const data = new FormData()
    data.append('content', JSON.stringify({reference, billingRequestIds, fileDeleted}))
    if(invoiceFile) {
      data.append('invoiceFile', invoiceFile)
    }

    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/invoice/update/' + billingRequestInvoiceId,
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  downloadInvoiceFile(invoiceId) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/billing-requests/invoice/' + invoiceId + '/file',
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getInvoice(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/invoice/get/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  submitInvoiceForReview(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/invoice/submit-for-review',
        method: 'post',
        data: {
          id,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  cancelInvoice(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/invoice/cancel',
        method: 'post',
        data: {
          id,
        }
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  acceptInvoice(id, reviewFeedback) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/invoice/accept',
        method: 'post',
        data: {
          id,
          reviewFeedback,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  rejectInvoice(id, reviewFeedback) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/invoice/reject',
        method: 'post',
        data: {
          id,
          reviewFeedback,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default new BillingRequestRepository()
